<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <div ref="apiData"></div>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import SwaggerUI from 'swagger-ui'
import { getDefinitionApiEndpoint } from '@/helpers/api'

export default {
  name: 'APIPage',
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {
    SwaggerUI({
      domNode: this.$refs.apiData,
      url: getDefinitionApiEndpoint('teamculture-api'),
    })
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
